$container--tabletPortrait: 768px;
$container--tabletLandscape: 1024px;
$container--desktopSmall: 1280px;
$container--desktopMedium: 1440px;
$container--desktopLarge: 1920px;

$mobile: "only screen and (max-width: #{$container--tabletPortrait - 1})";
$tablet: "only screen and (min-width: #{$container--tabletPortrait}) and (max-width: #{$container--desktopSmall - 1})";
$tabletPortrait: "only screen and (min-width: #{$container--tabletPortrait}) and (max-width: #{$container--tabletLandscape - 1})";
$tabletLandscape: "only screen and (min-width: #{$container--tabletLandscape}) and (max-width: #{$container--desktopSmall - 1})";
$desktopSmall: "only screen and (min-width: #{$container--desktopSmall}) and (max-width: #{$container--desktopMedium - 1})";
$desktop: "only screen and (min-width: #{$container--desktopMedium}) and (max-width: #{$container--desktopLarge - 1})";
$desktopBig: "only screen and (min-width: #{$container--desktopLarge})";
$desktopAll: "only screen and (min-width: #{$container--desktopSmall})";

@mixin media($type) {
  $media: $mobile;

  @if $type == mobile {
    $media: $mobile;
  } @else if $type == tablet {
    $media: $tablet;
  } @else if $type == tabletPortrait {
    $media: $tabletPortrait;
  } @else if $type == tabletLandscape {
    $media: $tabletLandscape;
  } @else if $type == portrait {
    $media: $tabletPortrait;
  } @else if $type == landscape {
    $media: $tabletLandscape;
  } @else if $type == desktopSmall {
    $media: $desktopSmall;
  } @else if $type == desktop {
    $media: $desktop;
  } @else if $type == desktopBig {
    $media: $desktopBig;
  } @else if $type == desktopAll {
    $media: $desktopAll;
  }

  @media #{$media} {
    @content;
  }
}
