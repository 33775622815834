@import "helpers";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  @include colors;

  --header-height: 75px;
  --sds-header-height: calc(47px + 24px); // with margin bottom

  --box-shadow: 0 9px 40px 0 rgba(116, 116, 116, 0.16);

  --rs-bg-active: var(--primary-400) !important;
  --rs-input-focus-border: var(--primary-400) !important;
  --rs-btn-primary-bg: var(--accent-800) !important;
  --rs-btn-primary-hover-bg: var(--accent-900) !important;
  --rs-calendar-cell-selected-hover-bg: var(--primary-500) !important;
  --rs-listbox-option-hover-bg: var(--primary-50) !important;
  --rs-listbox-option-hover-text: var(--dark) !important;
  --rs-calendar-range-bg: var(--primary-100) !important;
  --rs-text-active: var(--dark) !important;
  --rs-close-button-hover-color: var(--accent-900) !important;

  @include media(mobile) {
    --header-height: 77px;
  }
}

@include normalize;

body {
  font-family: Inter, sans-serif;
  background: var(--white);
}

* {
  outline-color: var(--accent-900);
}

// Table actions menu styles
.MuiDataGrid-menu {

  .MuiPaper-root {
    box-shadow: var(--box-shadow) !important;
  }

  .MuiList-root {
    padding: 0 !important;
  }
}

// remove blue outline from table cells
.MuiDataGrid-cell {
  outline: none !important;
}
