@mixin normalize {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include scrollbar;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  pre {
    margin: 0;
  }

  ul,
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    min-width: 0;
  }

  button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-family: inherit;
    text-align: inherit;
    cursor: pointer;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  figure {
    margin: initial;
    padding: initial;
  }

  mark {
    background-color: transparent;
    color: inherit;
  }
}
