@mixin colors {
  --white: #FFF;
  --grey: #89918E;
  --dark: #172536;
  // TODO: Replace with --red-300
  --red: #EB6060;
  --light-red: #FAF0F0;
  --yellow: #F3B216;
  --light-yellow: #FAF7F0;
  --green: #3FAE9D;
  --light-purple: #F4F0FA;

  --primary-50: #F5FAFF;
  --primary-100: #E3EAF2;
  --primary-200: #94ACC6;
  --primary-300: #6D89AA;
  --primary-400: #4C6A8E;
  --primary-500: #304F71;
  --primary-600: #172F4A;
  --primary-700: #1A2A3D;
  --primary-800: #0D1726;
  --primary-900: #05090F;

  --secondary-50: #FEFEFE;
  --secondary-100: #FAFBFC;
  --secondary-200: #F6F7F9;
  --secondary-300: #F0F4F7;
  --secondary-400: #EBF0F4;
  --secondary-500: #E3E8ED;
  --secondary-600: #D8DDE2;
  --secondary-700: #CDD2D7;
  --secondary-800: #ACB3B9;
  --secondary-900: #929AA0;
  --secondary-950: #727B83;

  --accent-50: #F0FAF8;
  --accent-100: #CEEDE9;
  --accent-200: #ADE1DA;
  --accent-300: #8CD5CB;
  --accent-400: #73CCC0;
  --accent-500: #5AC3B5;
  --accent-600: #52BDAE;
  --accent-700: #48B5A5;
  --accent-800: #3FAE9D;
  --accent-900: #2EA18D;

  --red-100: #F29797;
  --red-200: #EE7777;
  --red-300: #EB6060;
  --red-400: #EA5757;
}
