@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-200);
    border-radius: 3.5px;
  }
}

